// this page contains the state for all forecast components which share data with each other.
import { atom, selector } from 'recoil';
import { currentOrgId } from '../../../../store';
import OrganisationService from '../../../../service/organisation-service';
import { metrics } from '../../reports/recap/metric-groups';

export const outputs = [
  'receipts',
  'unit_sales',
  'on_hand',
  'cost_on_hand',
  'gross_margin',
  'gross_margin_pct',
  'weeks_on_hand',
  'cost_weeks_on_hand'
];
// locations
export const locationState = atom({
  key: 'forecast_location',
  default: -1
});

export const districtState = atom({
  key: 'forecast_district',
  default: -1
});

// versions
export const selectedVersionsState = atom({
  key: 'forecast_selected_versions',
  default: {
    main: 'ACTUALS',
    compare: 'WP'
  }
});

export const versionsState = atom({
  key: 'forecast_versions',
  default: []
});

// forecast
export const forecastState = atom({
  key: 'forecast',
  default: null
});

export const draftState = atom({
  key: 'forecast_draft',
  default: null
});

export const forecastOriginalState = atom({
  key: 'original-forecast',
  default: null
});

export const fetchingState = atom({
  key: 'forecast-loading',
  default: false
});

export const columnsState = atom({
  key: 'forecast-columns',
  default: []
});

// metrics
const metricsHiddenDefault = [
  'cost_weeks_on_hand',
  'gross_margin_pct',
  'auc_inventory',
  'auc_sales',
  'on_hand'
];

export const metricsDisplayedState = atom({
  key: 'forecast_metrics_displayed',
  default: (metrics || [])
    .filter(
      (m) =>
        [
          'unit_sales',
          'revenue',
          'receipts',
          'cost_receipts',
          'on_hand',
          'cost_on_hand',
          'gross_margin',
          'gross_margin_pct',
          'auv',
          'auc_sales',
          'auc_inventory',
          'weeks_on_hand',
          'cost_weeks_on_hand',
          'target_on_hand',
          'target_cost_on_hand',
        ].indexOf(m.value) >= 0
    )
    .map((m) => {
      const visible = metricsHiddenDefault.indexOf(m.value) === -1;
      return {
        ...m,
        visible
      };
    })
});

// products (class Ids)
export const currentClassIds = atom({
  key: 'forecast_product_type',
  default: []
});

export const pathState = atom({
  key: 'forecast_product_type_path',
  default: [null]
});

export const changesState = atom({
  key: 'forecast_changes',
  default: []
});

export const submittingChanges = atom({
  key: 'forecast_submitting_changes',
  default: false
});
