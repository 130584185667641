import { isEqual } from "lodash";
import { useStyles } from "./style";
import { useTheme } from "@mui/styles";
import React, { useEffect, useState } from "react";

// icons
import { IconButton } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import * as Muicon from "@mui/icons-material";

// MUI
import {
	ArrowLeft,
	ArrowRight,
	ChevronLeft,
	ChevronRight,
	AssessmentOutlined, SupportOutlined
} from '@mui/icons-material';

 // store
import { currentUser, drawerOpenState } from '../../../../store';

// components
import { NavLink } from "react-router-dom";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import { default as style } from "styled-components";
import { Drawer, DrawerHeader } from "./mui-drawer-styles";

// utils
import { useRecoilValue, useSetRecoilState } from 'recoil';

const Link = style(NavLink)`
  display: flex;
  color: #373737;
  flex: 1;
  
  &:hover {
  	span {
  		color: #15a58f;
  	}
  }
;`;

const LinkText = style.span`
  font-weight: 600;
  font-size: 15px;
  text-transform: uppercase;
  padding-left: 2px;
`;

const linkStyle = {
	height: "42px",
	flex: 1,
	paddingLeft: '14px'
};


const isDeepObjectsProxyEqual = (arr1, arr2) => {
	return Object.entries(arr1).every(([key, val]) => {
		if (!arr2[key]) return false
		return isEqual(val, arr2[key])
	})
}

const SideDrawer = ({ open, subscriptions }) => {
	const theme = useTheme();
	const classes = useStyles();
	const setDrawerOpen = useSetRecoilState(drawerOpenState);
	const [subscriptionsRoutes, setSubscriptionsRoutes] = useState(subscriptions);
	const user = useRecoilValue(currentUser);

	// this will be used to show bottom menu items
	const bottomNavModules = ['Admin', 'Support'];

	useEffect(() => {
		if (subscriptions) {
			const subscriptionsChanged = isDeepObjectsProxyEqual(subscriptionsRoutes, subscriptions)

			if (subscriptionsChanged) {
				setSubscriptionsRoutes(subscriptions)
			}
		}
	}, [subscriptions]);

	const generateNavMenus = (modules = []) => {
		const showReportsInNav = user?.orgSettings
			?.find(s => s.setting_key === 'show_reports_in_navigation')
			?.setting_value
			?.toLowerCase() === 'true';

		if (!showReportsInNav)
			modules = modules.filter(m => m.module_type !== 'report');

		modules.sort((a, b) => {
			if (a.navigation_order === null) return 1;
			if (b.navigation_order === null) return -1;
			return a.navigation_order - b.navigation_order || a.name.localeCompare(b.name);
		});

		return modules.map(module => {
			const Icon = Muicon[module.icon] ?? Muicon['AssessmentOutlined'];
			let externalURL = null;
			let link = module.link
			if (module.link === 'looker') {
				const settingValue = user
					?.orgSettings
					?.find(s => s.setting_key === 'looker_url' && s.scoped_entity_id === module.id)
					?.setting_value;

				link = `${module.link}/${settingValue}`;
			}
			if (module.link === 'url') {
				externalURL = user
					?.orgSettings
					?.find(setting => setting.setting_key === 'url' && setting.scoped_entity_id === module.id)
					?.setting_value;
			}

			const menuItemContent = (				
			<MenuItem sx={linkStyle} disableRipple>
				<ListItemIcon>
					<Icon fontSize="large" sx={{ fontSize: 24 }} />
				</ListItemIcon>
				<ListItemText>
					<LinkText>{module.name}</LinkText>
				</ListItemText>
			</MenuItem>);
				if (externalURL){
					return (
						<a
							href={externalURL}
							target="_blank"
							key={`module_${module.id}`}
						>
							{menuItemContent}
						</a>
					)
				}
			return <Link to={link ?? '/'} key={`module_${module.id}`}>{menuItemContent}</Link>
		});
	};

	const topMenuItems = generateNavMenus(
		user
			?.userPermissions
			?.filter(m => !bottomNavModules.includes(m.name)));

	const bottomNavItems = generateNavMenus(user
		?.userPermissions
		?.filter(m => bottomNavModules.includes(m.name)));

	return (<Drawer
		variant="permanent"
		open={open}
		className={classes.drawer}
	>
		<DrawerHeader>
			<IconButton onClick={() => setDrawerOpen(false)}>
				{theme.direction === "rtl" ? (
					<ChevronRight />
				) : (
					<ChevronLeft />
				)}
			</IconButton>
		</DrawerHeader>
		<MenuList className={classes.menuList}>
			{topMenuItems}
		</MenuList>
		<div className={classes.bottomNavList}>
			<MenuList>
				{bottomNavItems}
				<a
					href="http://www.growthsayer.com/"
					target="_blank"
				>
					<MenuItem sx={linkStyle} disableRipple>
						<ListItemIcon>
							<SupportOutlined fontSize="large" sx={{ fontSize: 24 }} />
						</ListItemIcon>
						<ListItemText>
							<LinkText>Support</LinkText>
						</ListItemText>
					</MenuItem>
				</a>
			</MenuList>
		</div>
		<span
			className={classes.arrowControl}
			onClick={open ? () =>
					setDrawerOpen(false) :
				() => setDrawerOpen(true)}
		>
			{open ? <ArrowLeft /> : <ArrowRight />}
		</span>
	</Drawer>)
}

export default React.memo(SideDrawer);
