import {
  addCommas,
  formatDollar,
  toPercent,
} from "../../../../utils/textFormatter";
const formatDollarFunc = (n, decimal) => `${formatDollar(n, decimal)}`;

export const metrics = [
  {
    value: "revenue",
    display: "Revenue",
    visible: true,
    precision: 0,
    format: (v) => formatDollarFunc(v || 0, 0),
  },
  {
    value: "unit_sales",
    display: "Unit Sales",
    visible: true,
    precision: 0,
    format: (val) => addCommas(val || 0),
  },
  {
    value: "auv",
    display: "AUV",
    visible: false,
    precision: 2,
    format: (val) => formatDollarFunc(val || 0, 2),
  },
  {
    value: "auc_sales",
    display: "AUC (Sales)",
    visible: false,
    precision: 2,
    format: (val) => formatDollarFunc(val || 0, 2),
  },
  {
    value: "auc_inventory",
    display: "AUC (Inv)",
    visible: false,
    precision: 2,
    format: (val) => formatDollarFunc(val || 0, 2),
  },
  {
    value: "gross_margin",
    display: "Gross Margin",
    visible: false,
    precision: 0,
    format: (val) => formatDollarFunc(val || 0, 0),
  },
  {
    value: "weeks_on_hand",
    display: "Weeks on Hand",
    visible: true,
    precision: 1,
    format: (val) => addCommas(val || 0, 1),
  },
  {
    value: "cost_weeks_on_hand",
    display: "Cost Weeks on Hand",
    visible: true,
    precision: 1,
    format: (val) => addCommas(val || 0, 1),
  },
  {
    value: "cost_on_hand",
    display: "Cost on hand",
    visible: true,
    precision: 0,
    format: (val) => formatDollarFunc(val || 0, 0),
  },
  {
    value: "on_hand",
    display: "On Hand",
    visible: true,
    precision: 0,
    format: addCommas,
  },
  {
    value: "cogs",
    display: "COGS",
    visible: false,
    precision: 0,
    format: formatDollarFunc,
  },
  {
    value: "sell_through",
    display: "Sell Through",
    visible: false,
    precision: 1,
    format: (val) => toPercent(val || 0, 1, 100),
  },
  {
    value: "gross_margin_pct",
    display: "Gross Margin %",
    visible: true,
    precision: 1,
    format: (val) => toPercent(val || 0, 1, 100),
  },
  {
    value: "receipts",
    display: "Receipts",
    visible: false,
    precision: 0,
    format: addCommas,
  },
  {
    value: "cost_receipts",
    display: "Cost Receipts",
    visible: false,
    precision: 0,
    format: formatDollarFunc,
  },
  {
    value: "target_on_hand",
    display: "Target on Hand",
    visible: false,
    precision: 0,
    format: addCommas,
  },
  {
    value: "target_cost_on_hand",
    display: "Target Cost on Hand",
    visible: false,
    precision: 0,
    format: formatDollarFunc,
  }
];
