import Backdrop from '@mui/material/Backdrop';
import React from 'react';

const loadingStyle = {
    overlay: (base) => ({
        ...base,
        background: 'rgba(255, 255, 255, 0.8)',
        color: 'black',
        width: '100%',
        height: '32px',
    })
};

const Spinner = ({ loading }) => {
    return   <Backdrop
        open={loading}
        styles={loadingStyle}
        text={<div style={{ background: 'white' }}>
            Loading data...
        </div>}
    >
    </Backdrop>;
};

export default Spinner;
