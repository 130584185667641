// libs
import NavMenu from '@/components/NavMenu/NavMenu';
import EmailIcon from "@mui/icons-material/Email";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PictureAsExcel from '@mui/icons-material/Description';
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Fade from "@mui/material/Fade";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import axios from "axios";
import moment from "moment";
import { useSnackbar } from "notistack";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import React, { useEffect, useMemo, useState } from 'react';
import CurrencyInput from "react-currency-input-field";
import DataTable from "react-data-table-component";
import { useLocation, useNavigate } from "react-router-dom";
import ClassSelector from "../../../../../../components/ClassSelector";
import Loader from "../../../../../../components/Loader";
import Tooltip from '@mui/material/Tooltip';

import SettingsService from '@/service/org/setting.service';
import VendorService from '@/service/org/vendor.service';

import XLSX from "xlsx";

// atoms
import { importedPurchaseOrderAtom } from '../PurchaseOrderCreate/PurchaseOrderCreate'

// deps
import OrganisationService from "../../../../../../service/organisation-service";
import { addCommas } from "../../../../../../utils/textFormatter";
import Filter from "../../components/Filter";
import ProductTypeFilter from "../../components/ProductTypeFilter";
import PurchaseOrderHistory from "../PurchaseOrderHistory/PurchaseOrderHistory";
import CellEditor from "./CellEditor";
import {
  CancelButton,
  Cell,
  CenteredCell,
  DeleteButton,
  ExportModalHeader,
  ExportOption,
  Figure,
  FigureLabel,
  FigureValue,
  FiltersContainer,
  Icon,
  OKButton,
  RightAlignedCell
} from "./style";
// styles
import CircularProgress from "@mui/material/CircularProgress";
import { useRecoilValue } from "recoil";
import { ACCESS_TOKEN_KEY, currentOrg, locations as locationsState } from '../../../../../../store';
import "./style.css";
import Autocomplete from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

// fonts for pdfMake
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const modes = {
  CREATE: { label: "Create" },
  EDIT: { label: "Edit" },
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "white",
    border: "none",
    zIndex: 99999999999,
    padding: "20px"
  },
  loader: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
}));

const PurchaseOrderEdit = () => {
  const org = useRecoilValue(currentOrg);
  const orgId = org.org_id;
  const forecastAPI = OrganisationService.forecast(orgId);

  const [totalUnits, setTotalUnits] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [totalGross, setTotalGross] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [brands, setBrands] = useState([]);
  const [products, setProducts] = useState([]);

  // filters
  const [brandFilter, setBrandFilter] = useState(null);
  const [productTypes, setProductTypes] = useState(null);
  const [productTypeMap, setProductTypeMap] = useState(null);
  const [productTypeFilter, setProductTypeFilter] = useState(null);

  const [isClassModalOpen, setIsClassModalOpen] = useState(false);
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isConfirmDuplicateModalOpen, setIsConfirmDuplicateModalOpen] = useState(false);
  const [isDeletePOModalOpen, setIsDeletePOModalOpen] = useState(false);
  const [isExportPdfModalOpen, setIsExportPdfModalOpen] = useState(false);
  const [exportedPdfFileName, setExportedPdfFileName] = useState("");
  const [lineItemsToDelete, setLineItemsToDelete] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeletingPO, setIsDeletingPO] = useState(false);
  const [isDuplicatingPO, setIsDuplicatingPO] = useState(false);
  const [isPOHistoryModalOpen, setIsPOHistoryModalOpen] = useState(false);
  const [vendor, setVendor] = useState(null);
  const [vendors, setVendors] = useState([]);

  const [loc, setLocation] = useState(null);
  const locations = useRecoilValue(locationsState);

  const classes = useStyles();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const po = query.get("po");
  const { enqueueSnackbar } = useSnackbar();

  // the main variable that stores the current purchase order working state
  const [originalPurchaseOrder, setOriginalPurchaseOrder] = useState({});
  const [purchaseOrder, setPurchaseOrder] = useState({});

  // used only for importing lines items from reorder-report
  const importedPurchaseOrder = useRecoilValue(importedPurchaseOrderAtom);

  // this will be used to generate records that haven't been persisted yet
  const getTemporaryId = () =>
    parseInt((Math.random() * 10000000).toString(), 10);

  const navigate = useNavigate();

  // the last row in the table
  const recordToInsert = {
    id: null,
    item_id: null,
    auc: 0,
    brand_name: "",
    case_qty: 1,
    case_count: 0,
    order_qty: 0,
    cost: 0,
    h2: "",
    h3: "",
    product_name: "",
    draft: true,
  };

  // called when creating a new line item
  const onSelect = (h2, h3) => {
    setIsClassModalOpen(false);
    if (!h2 || !h3) return;

    // set last item in table to the value selected from modal.
    const lastItem =
      purchaseOrder.lineItems[purchaseOrder.lineItems.length - 1];
    purchaseOrder.lineItems[purchaseOrder.lineItems.length - 1] = {
      ...lastItem,
      h2_id: h2.id,
      h2: h2.name,
      h3_id: h3.id,
      class_id: h3.id,
      h3: h3.name,
      draft: false,
    };

    setPurchaseOrder({
      ...purchaseOrder,
      lineItems: [...purchaseOrder.lineItems, recordToInsert],
    });
  };

  const createNewPO = async (submitPOLineItems) => {
    try {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BASE_API_URL}/v1/api/orders/purchase-orders`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
          "content-type": "application/json",
        },
        data: {
          org_public_id: orgId,
          status: "NOT_SUBMITTED",
          vendor_id: purchaseOrder.brand_id,
          location_id: purchaseOrder.location_id,
          terms: purchaseOrder.terms,
          delivery_date: purchaseOrder.delivery_date,
          payment_after: parseInt(purchaseOrder.payment_after),
          lineItems: submitPOLineItems,
        },
      });
      enqueueSnackbar("Created purchase order", { variant: "success" });
      navigate(`/account/orders`);
    } catch (e) {
      console.log(e);
      enqueueSnackbar("Create purchase order failed", { variant: "error" });
    }
  };

  const updateExistingPO = async (lineItems) => {
    try {
      await axios({
        method: "put",
        url:
          `${process.env.REACT_APP_BASE_API_URL}/v1/api/orders/purchase-orders/${purchaseOrder.po_number}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`,
          "content-type": "application/json",
        },
        data: {
          org_public_id: orgId,
          status: purchaseOrder.status,
          vendor_id: purchaseOrder.brand_id,
          location_id: purchaseOrder.location_id,
          terms: purchaseOrder.terms,
          delivery_date: purchaseOrder.delivery_date,
          payment_after: purchaseOrder.payment_after,
          lineItems: lineItems,
          lineItemsToDelete: lineItemsToDelete,
        },
      });
      enqueueSnackbar("Updated purchase order", { variant: "success" });
    } catch (e) {
      enqueueSnackbar("Update purchase order failed", { variant: "error" });
    }
  };

  const savePO = async () => {
    const legitItems = purchaseOrder.lineItems.filter((items) => !items.draft);
    // In case all items are drafts/invalid
    if (legitItems.length === 0) {
      enqueueSnackbar(`New products not found`, { variant: "warning" });
    } else {
      const lineItems = legitItems.map((item) => {
        return {
          ...item,
          item_id: parseInt(item.item_id),
          order_qty: item.order_qty,
          case_qty: parseInt(item.case_qty),
          auc: parseFloat(item.auc),
          class_id: item.h3_id,
          case_count: item.case_count,
          brand_name: item.brand_name,
          brand_id: item.brand_id,
          product_name: item.product_name,
        };
      });

      try {
        // validation checks
        for (const [i, lineItem] of (lineItems || []).entries()) {
          if (lineItem.case_qty === 0) {
            enqueueSnackbar(
              `${lineItem.product_name} cases must be bigger than zero`,
              { variant: "error" }
            );
            return false;
          }

          if (lineItem.case_count === 0) {
            enqueueSnackbar(
              `${lineItem.product_name} case count must be bigger than zero`,
              { variant: "error" }
            );
            return false;
          }

          if ((lineItem.brand_name || "").length === 0) {
            enqueueSnackbar(`${lineItem.product_name} must have a brand`, {
              variant: "error",
            });
            return false;
          }

          if ((lineItem.product_name || "").length === 0) {
            enqueueSnackbar(`A product is missing its name`, {
              variant: "error",
            });
            return false;
          }
        }

        setIsSaving(true);
        switch (purchaseOrder.mode) {
          case "CREATE": {
            await createNewPO(lineItems);
            break;
          }
          case "EDIT": {
            await updateExistingPO(lineItems);
            break;
          }
        }
      } catch (e) {
        enqueueSnackbar(`Error occurred while saving`, { variant: "error" });
      } finally {
        setIsSaving(false);
      }
    }
  };

  const loadExistingPO = async (po) => {
    try {
      const { data: poData } = await OrganisationService.po.listByPoNumber(
        orgId,
        po
      );

      const lineItems = poData.lineItems.map((item) => {
        return {
          ...item,
          order_qty: item.case_count * item.case_qty,
          cost: item.order_qty * item.auc,
        };
      });
      setPurchaseOrder({
        ...poData,
        mode: "EDIT",
        lineItems: [...lineItems, recordToInsert],
      });
      const snapshot = JSON.parse(
        JSON.stringify([...lineItems, recordToInsert])
      );
      setOriginalPurchaseOrder({
        ...poData,
        mode: "EDIT",
        lineItems: snapshot,
      });
    } catch (e) { }
  };

  const loadRecommendedItems = async () => {
    // set RO lead time
    const now = new Date();
    now.setHours(0, 0, 0);

    try {
      const { data: orgSettings } = await SettingsService.getSettings();
      const roLeadTime = (orgSettings?.['RO Lead Time (in weeks)'] ?? 0) * 7;
      now.setDate(now.getDate() + roLeadTime);
    } catch (e) {
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      });
    }

    // map values from reorder report to db fields
    const lineItems = importedPurchaseOrder.lineItems.map((item, i) => {
      const order_qty = parseInt(item?.["Final Reco"]);
      const case_qty =
        parseInt(item?.["Case Pack Size"]) === 0
          ? 1
          : parseInt(item?.["Case Pack Size"]);
      const case_count = Math.ceil(order_qty / case_qty);
      const auc = parseFloat(item?.["AUC"]);
      const on_hand = parseInt(item?.["Current On Hand"]);
      const avg_unit_sales = parseFloat(item?.["Avg Weekly Sales"]).toFixed(1);
      const target_oh = parseInt(item?.["Future Target On Hand"]);

      return {
        id: getTemporaryId(),
        item_id: item?.["Item ID"],
        brand_id: parseInt(item?.["Brand ID"]),
        brand_name: item?.["Brand Name"],
        product_name: item?.["Product Name"],
        h2: item?.["Category"],
        h3: item?.["Sub Category"],
        auc: auc.toFixed(2),
        on_hand: on_hand,
        avg_unit_sales: avg_unit_sales,
        target_oh: target_oh,
        order_qty: order_qty,
        case_qty: case_qty,
        case_count: case_count,
        cost: order_qty * auc.toFixed(2),
        tax_rate: item?.["Tax Rate"],
      };
    });

    setPurchaseOrder({
      ...importedPurchaseOrder,
      lineItems: [...lineItems, recordToInsert],
      delivery_date: now.toISOString(),
      terms: "Net 30",
      payment_after: "30",
    });

    setOriginalPurchaseOrder({
      ...importedPurchaseOrder,
      lineItems: [...lineItems, recordToInsert],
      delivery_date: now.toISOString(),
      terms: "Net 30",
      payment_after: "30",
    });
  };

  // Initial page load
  useEffect(() => {
    if (!!po) {
      loadExistingPO(po);
    } else if (importedPurchaseOrder.lineItems) {
      loadRecommendedItems();
    } else {
      // blank po
      setPurchaseOrder({
        ...importedPurchaseOrder,
        lineItems: [recordToInsert],
        delivery_date: new Date().toISOString(),
        terms: "Net 30",
        mode: "CREATE",
        payment_after: "30",
      });
      setOriginalPurchaseOrder({
        ...importedPurchaseOrder,
        lineItems: [recordToInsert],
        delivery_date: new Date().toISOString(),
        terms: "Net 30",
        mode: "CREATE",
        payment_after: "30",
      });
    }

    // load brands & products
    // load brands
    const loadBrands = async () => {
      const { data: brands } = await OrganisationService.brands.list(orgId);
      setBrands((brands || []).map((b) => b.name));
    };

    // load products
    const loadProducts = async () => {
      const { data: products } = await OrganisationService.products().all(
        orgId
      );
      setProducts((products || []).map((b) => b.name));
    };

    const loadVendors = async () => {
      try {
        const {
          data ,
        } = await VendorService.getVendors();
        setVendors(data);
      } catch (e) {
        console.log(e);
      }
    };

    loadBrands().then();
    loadProducts().then();
    loadVendors().then()
  }, []);

  useEffect(() => {
    if (!purchaseOrder.lineItems) {
      return;
    }

    if (!purchaseOrder.lineItems.length) {
      setTotalCost(0);

      setPurchaseOrder({
        ...purchaseOrder,
        lineItems: [recordToInsert],
      });
      return;
    }

    let totalUnits = 0;
    let totalCost = 0;
    let totalTax = 0;

    for (let i = 0; i < purchaseOrder.lineItems.length - 1; i++) {
      if (purchaseOrder.lineItems[i].order_qty) {
        totalUnits += purchaseOrder.lineItems[i].order_qty;
      }
      if (purchaseOrder.lineItems[i].order_qty) {
        totalCost += purchaseOrder.lineItems[i].cost;
        totalTax +=
          purchaseOrder.lineItems[i].cost *
          (purchaseOrder.lineItems[i].tax_rate || 0);
      }
    }

    setTotalCost(totalCost);
    setTotalUnits(totalUnits);
    setTotalTax(totalTax);
    setTotalGross(totalCost - totalTax);
  }, [importedPurchaseOrder, purchaseOrder]);


  const filteredBrands = useMemo(() => {
    const poBrands = purchaseOrder.lineItems?.map((item) => item.brand_name)?.filter((brand) => !!brand);
    const uniquePoBrands = [...new Set(poBrands)];
    return brands.filter((brand) => uniquePoBrands.includes(brand));
  }, [purchaseOrder, brands]);

  const filteredProductTypes = useMemo(() => {
    return purchaseOrder?.lineItems?.map((item) => item.class_id);
  }, [productTypes, purchaseOrder]);

  const handleAUCChange = (val, record) => {
    if (!val) val = "";
    if (val?.endsWith(".")) {
      record.auc = val;
    } else if (val === "") {
      record.auc = 0;
    } else {
      record.auc = val;
    }
    record.cost = record.case_count * record.case_qty * val;
    setPurchaseOrder({
      ...purchaseOrder,
    });
  };

  const updateCaseCount = (case_count, record) => {
    record.case_count = case_count;
    record.order_qty = case_count * record.case_qty;
    record.cost = case_count * record.case_qty * record.auc;

    setPurchaseOrder({
      ...purchaseOrder,
    });
  };

  const updateUnitsPerCase = (case_qty, record) => {
    record.case_qty = case_qty;
    record.order_qty = case_qty * record.case_count;
    record.cost = case_qty * record.case_count * record.auc;

    setPurchaseOrder({
      ...purchaseOrder,
    });
  };

  const deleteLineItem = (lineItem, index) => {
    if (mode === modes.EDIT) {
      setLineItemsToDelete([...lineItemsToDelete, lineItem.id]);
    }

    const filterData = purchaseOrder.lineItems.filter((item, c) => index !== c);
    setPurchaseOrder({
      ...purchaseOrder,
      lineItems: filterData,
    });
  };

  const copyLineItem = (record, index) => {
    const { lineItems } = purchaseOrder;
    const copyRecordToInsert = {
      ...record,
      id: getTemporaryId(),
      copied: true,
    };

    lineItems.pop();
    lineItems.splice(index, 0, copyRecordToInsert);
    lineItems.push(recordToInsert);

    setPurchaseOrder({
      ...purchaseOrder,
      lineItems: purchaseOrder.lineItems,
    });
  };

  const columns = () => [
    {
      name: "",
      width: "50px",
      cell: (record, index) => {
        return (
          <span style={{ paddingLeft: "10px" }}>{(index + 1).toString()}</span>
        );
      },
    },
    {
      name: "Product Type",
      selector: (row) => row.h2,
      sortable: true,
      cell: (record, index) => {
        return <Cell style={{ paddingLeft: "10px" }}>{record.h2}</Cell>;
      },
    },
    {
      name: "Sub Type",
      selector: (row) => row.h3,
      sortable: true,
    },
    {
      name: "Brand",
      selector: (row) => row.brand_name,
      sortable: true,
      cell: (record, index) => {
        return (
          <Cell>
            <CellEditor
              text={record.brand_name}
              placeholder="Brand Name"
              editing={true}
              options={brands}
              onValueChange={(e) => {
                let poUpdate = [...purchaseOrder.lineItems];
                poUpdate[index].brand_name = e;
                setPurchaseOrder({
                  ...purchaseOrder,
                  lineItems: poUpdate,
                });
              }}
            />
          </Cell>
        );
      },
    },
    {
      name: "Product Name",
      selector: (record) => record.product_name,
      width: "200px",
      sortable: true,
      minWidth: "200px !important",
      cell: (record, index) => {
        return (
          <Tooltip title={record.product_name}>
            <Cell>
              <CellEditor
                text={record.product_name}
                placeholder="Product Name"
                options={products.filter(p => !!p)}
                onValueChange={(e) => {
                  let poUpdate = [...purchaseOrder.lineItems];
                  poUpdate[index].product_name = e;
                  setPurchaseOrder({
                    ...purchaseOrder,
                    lineItems: poUpdate,
                  });
                }}
              />
            </Cell>
          </Tooltip>
        );
      },
    },
    {
      name: "Cases",
      selector: (row) => row.case_count,
      sortable: true,
      width: "100px",
      minWidth: "100px !important",
      right: true,
      cell: (record) => {
        return (
          <Cell>
            <div style={{ backgroundColor: '#f1f1f1', padding: '4px', border: 'solid 1px #bbb' }}>
              <CellEditor
                style={{ textAlign: "right" }}
                text={record.case_count}
                onValueChange={(case_count) =>
                  updateCaseCount(case_count, record)
                }
                number={true}
              />
            </div>
          </Cell>
        );
      },
    },
    {
      name: "Units per Case",
      selector: (row) => row.case_qty,
      sortable: true,
      width: "100px",
      minWidth: "100px !important",
      right: true,
      cell: (record, index) => {
        return (
          <Cell>
            <div style={{ backgroundColor: '#f1f1f1', padding: '4px', border: 'solid 1px #bbb' }}>
              <CellEditor
                style={{ textAlign: "right" }}
                text={record.case_qty}
                onValueChange={(case_qty) => updateUnitsPerCase(case_qty, record)}
                number={true}
              />
            </div>
          </Cell>
        );
      },
    },
    {
      name: "Order",
      selector: (row) => row.order_qty,
      sortable: true,
      width: "100px",
      minWidth: "100px !important",
      right: true,
      cell: (record, index) => (
        <RightAlignedCell>
          <span>{record.order_qty}</span>
        </RightAlignedCell>
      ),
    },
    {
      name: "AUC",
      selector: (row) => row.auc,
      sortable: true,
      width: "100px",
      minWidth: "100px !important",
      right: true,
      cell: (record, index) => {
        return (
          <Cell>
            <div style={{ backgroundColor: '#f1f1f1', padding: '4px', border: 'solid 1px #bbb' }}>
            <CurrencyInput
              style={{ textAlign: "right" }}
              className="hide-input-border"
              prefix="$"
              value={record.auc}
              step={1}
              onValueChange={(
                event,
                maskedvalue,
                floatvalue) =>
                handleAUCChange(event, record)
              }
            />
            </div>
          </Cell>
        );
      },
    },
    {
      name: "Cost",
      selector: (row) => row.cost,
      width: "100px",
      minWidth: "100px !important",
      sortable: true,
      right: true,
      cell: (record, index) => {
        return (
          <RightAlignedCell>
            <span>${addCommas(record.cost)}</span>
          </RightAlignedCell>
        );
      },
    },
    {
      name: "OH",
      selector: (row) => row.on_hand,
      omit: purchaseOrder.mode === "EDIT",
      sortable: true,
      width: "100px",
      minWidth: "100px !important",
      right: true,
      cell: (record, index) => {
        return (
          <RightAlignedCell>
            <span>{record.on_hand || "-"}</span>
          </RightAlignedCell>
        );
      },
    },
    {
      name: "Avg Weekly Sales",
      selector: (row) => row.ave_unit_sales,
      sortable: true,
      omit: purchaseOrder.mode === "EDIT",
      width: "100px",
      minWidth: "100px !important",
      right: true,
      cell: (record, index) => {
        return (
          <RightAlignedCell>
            <span>{record.avg_unit_sales || "-"}</span>
          </RightAlignedCell>
        );
      },
    },
    {
      name: "Target OH",
      selector: (row) => row.target_oh,
      sortable: true,
      omit: purchaseOrder.mode === "EDIT",
      width: "100px",
      minWidth: "100px !important",
      right: true,
      cell: (record, index) => {
        return (
          <RightAlignedCell>
            <span>{record.target_oh || "-"}</span>
          </RightAlignedCell>
        );
      },
    },
    {
      name: "Actions",
      center: true,
      cell: (record, i) => {
        if (record.draft) {
          return (
            <Icon
              className="material-icons mr-1 icon-action"
              style={{ cursor: "pointer" }}
              onClick={() => setIsClassModalOpen(true)}
            >
              add_box
            </Icon>
          );
        } else {
          return (
            <CenteredCell>
              <Icon
                className="material-icons mr-1 icon-action"
                style={{ cursor: "pointer" }}
                onClick={() => copyLineItem(record, i)}
              >
                content_copy
              </Icon>
              <Icon
                className="material-icons mr-1 icon-action"
                style={{ cursor: "pointer" }}
                onClick={() => deleteLineItem(record, i)}
              >
                delete
              </Icon>
            </CenteredCell>
          );
        }
      },
    },
  ];

  let buttons = [];
  const mode = purchaseOrder.mode === "CREATE" ? modes.CREATE : modes.EDIT;
  const title = `${mode.label} Purchase Order`;

  const reset = () => {
    const lineItems = originalPurchaseOrder.lineItems.map((item) => ({
      ...item,
      id: getTemporaryId(),
    }));
    setPurchaseOrder({
      ...originalPurchaseOrder,
      lineItems,
    });
  };

  if (mode === modes.EDIT) {
    buttons = [
      {
        icon: "save",
        title: "save",
        description: "save",
        action: () => savePO(),
      },
      {
        icon: "get_app",
        title: "export",
        description: "export",
        action: async () => {
          await savePO();
          setIsExportModalOpen(true);
        },
      },
      {
        icon: "content_copy",
        title: "duplicate",
        description: "duplicate",
        action:  () => {
          const currentLocation = locations.find((loc) => loc.id === purchaseOrder.location_id);
          setLocation(
            {
              ...currentLocation,
              value: currentLocation.id,
              label: currentLocation.location_name,
            }
          );

          const currentVendor = vendors.find((v) => v.id === purchaseOrder.vendor_id);
          setVendor({
              ...currentVendor,
              value: currentVendor.id,
              label: currentVendor.distributor_name,
            }
          );

          setIsConfirmDuplicateModalOpen(true);
        },
      },
      {
        icon: "history",
        title: "reset",
        description: "reset",
        action: () => reset(),
      },
      {
        icon: "menu_book",
        title: "History",
        description: "History",
        action: () => {
          setIsPOHistoryModalOpen(true);
        },
      },
      {
        icon: "delete",
        title: "delete",
        description: "delete",
        action: () => {
          setIsDeletePOModalOpen(true);
        },
      },
    ];
  } else {
    buttons = [
      {
        icon: "save",
        title: "save",
        description: "save",
        action: () => savePO(),
      },
      {
        icon: "restore",
        title: "reset",
        description: "reset",
        action: () => reset(),
      },
    ];
  }

  const heComponent = (
    <ClassSelector onSelect={onSelect} serviceProvider={forecastAPI} key={1} />
  );

  const isLoadingPo = Object.keys(purchaseOrder).length === 0;

  const EmailExport = async () => {
    try {
      await OrganisationService.po.export(orgId, purchaseOrder.po_number);
      // TBD: show snackbar
    } catch (e) {
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      });
    } finally {
      setIsExportModalOpen(false);
    }
  };

  const ExportAsPDF = () => {
    setIsExportModalOpen(false);
    const pdfFileName = generatePdfFileName();
    setExportedPdfFileName(pdfFileName);
    setIsExportPdfModalOpen(true);
  };

  const getUniqueItems = () => {
    return purchaseOrder.lineItems
      ? purchaseOrder.lineItems.length - 1
      : 0;
  };

  const ExportAsExcel = () => {
    const uniqueItems = getUniqueItems();

    const workbook = XLSX.utils.book_new();
    const sheet = [
      ['PO Number', po],
      ['Vendor', purchaseOrder?.distributor?.name],
      ['Ship To', purchaseOrder?.shipTo?.name],
      ['Expected Delivery Date', purchaseOrder?.delivery_date],
      ['Status', purchaseOrder?.status],
      ['Terms', purchaseOrder?.terms],
      ['Total Cost', `$${addCommas(totalCost)}`],
      ['Total Gross', `$${addCommas(totalGross)}`],
      ['Total Tax', `$${addCommas(totalTax)}`],
      ['Total Units', addCommas(totalUnits)],
      ['Unique Items', addCommas(uniqueItems)],
    ];

    sheet.push([]);
    sheet.push([]);
    sheet.push([]);

    sheet.push([
      'Product Type',
      'Sub Type',
      'Brand',
      'Product Name',
      'Cases',
      'Units per Case',
      'Order',
      'AUC',
      'Cost'
    ]);

    // format line items
    filteredLineItems()
      .slice(0, -1) // all rows but last
      .forEach((item) => {
        sheet.push([
          item.h2,
          item.h3,
          item.brand_name,
          item.product_name,
          item.case_count,
          item.case_qty,
          item.order,
          item.auc,
          `$${item.cost}`
        ])
      });

    const worksheet = XLSX.utils.aoa_to_sheet(sheet);

    // Add the worksheet to the workbook
    const filename = `po-${po}`;
    XLSX.utils.book_append_sheet(workbook, worksheet, filename);

    // Save the workbook
    XLSX.writeFile(workbook, `${filename}.xlsx`);
  };

  const saveAsPdf = () => {
    try {
      const uniqueItems = getUniqueItems();
      const { lineItems } = purchaseOrder;

      // created on
      const createdOn = moment(purchaseOrder.created_at);
      const month = createdOn.format("MMM");
      const day = createdOn.format("D");
      const year = createdOn.format("YYYY");
      const createdOnFormatted = `created on ${month} ${day}, ${year}`;

      const lineItemsFormatted = lineItems
        .filter((_, j) => j !== lineItems.length - 1)
        .map((item, i) => {
          return [
            {
              text: item.brand_name,
              style: i % 2 === 0 ? "bodyCellGray" : "bodyCellWhite",
            },
            {
              text: item.product_name,
              style: i % 2 === 0 ? "bodyCellGray" : "bodyCellWhite",
            },
            {
              text: addCommas(item.order_qty),
              style: i % 2 === 0 ? "numbersGray" : "numbersWhite",
            },
            {
              text: `${item.auc}`,
              style: i % 2 === 0 ? "numbersGray" : "numbersWhite",
            },
            {
              text: addCommas(item.cost),
              style: i % 2 === 0 ? "numbersGray" : "numbersWhite",
            },
          ];
        });

      const tableStyles = {
        header: {
          fontSize: 11,
          color: "#707070",
          bold: true,
        },
        rightHeader: {
          alignment: "right",
          fontSize: 11,
          color: "#707070",
          bold: true,
        },
        numbersWhite: {
          alignment: "right",
          fontSize: 11,
        },
        numbersGray: {
          alignment: "right",
          fontSize: 11,
          fillColor: "#f1f1f1",
        },
        bodyCellWhite: {
          lineHeight: 1,
          fontSize: 11,
        },
        bodyCellGray: {
          lineHeight: 1,
          fontSize: 11,
          fillColor: "#f1f1f1",
        },
      };

      const header = {
        layout: "noBorders",
        table: {
          widths: ["*", "*", "*"],
          body: [
            [
              {
                text: org.name,
                fontSize: 18,
                bold: true,
              },
              {
                text: "", // TBD: userInfo.address,
                fontSize: 11,
                margin: [0, 30, 0, 0],
              },
              {
                text: "PURCHASE ORDER",
                margin: [50, 30, 0, 0],
                fontSize: 15,
                bold: true,
              },
            ],
            [
              {
                text: "", // TBD: userInfo.website,
                margin: [0, 5, 0, 0],
              },
              {
                text: "", // TBD: `${userInfo.city}, ${userInfo.state}`,
                fontSize: 11,
                margin: [0, 5, 0, 10],
              },
              {
                text: purchaseOrder.po_number,
                margin: [50, 10, 0, 0],
                bold: true,
                fontSize: 12,
              },
            ],
            [
              {
                text: "", // TBD: userInfo.email,
                margin: [0, 0, 0, 10],
              },
              {
                text: "", // TBD: userInfo.zip,
                fontSize: 10,
              },
              {
                text: createdOnFormatted,
                margin: [50, 7, 0, 0],
                fontSize: 11,
              },
            ],
          ],
        },
      };

      const vendor = [
        {
          text: "Vendor",
          bold: true,
          fontSize: 11,
        },
        {
          text: purchaseOrder.distributor.name,
          fontSize: 11,
        },
        {
          text: purchaseOrder.distributor.website,
          fontSize: 11,
        },
        {
          text: purchaseOrder.distributor.email,
          fontSize: 11,
        },
        {
          text: purchaseOrder.distributor.address,
          fontSize: 11,
        },
        {
          text: `${purchaseOrder.distributor.city}, ${purchaseOrder.distributor.state}`,
          fontSize: 11,
        },
        {
          text: purchaseOrder.distributor.zip,
          fontSize: 11,
        },
      ];

      const shipToSection = [
        {
          text: "Ship To",
          bold: true,
          fontSize: 11,
          margin: [200, 0, 0, 0],
        },
        {
          text: purchaseOrder.shipTo.name,
          fontSize: 11,
          margin: [200, 0, 0, 0],
        },
        {
          text: purchaseOrder.shipTo.address,
          fontSize: 11,
          margin: [200, 0, 0, 0],
        },
        {
          text: `${purchaseOrder.shipTo.city}, ${purchaseOrder.shipTo.state}`,
          fontSize: 11,
          margin: [200, 0, 0, 0],
        },
        {
          text: `${purchaseOrder.shipTo.zip}`,
          fontSize: 11,
          margin: [200, 0, 0, 0],
        },
      ];

      const vendorLine = {
        layout: "noBorders",
        margin: [0, 30, 0, 0],
        table: {
          body: [[vendor, shipToSection]],
        },
      };

      const poSummary = {
        layout: "noBorders",
        table: {
          body: [
            [
              {
                text: "ITEMS",
                fontSize: 11,
                color: "#707070",
                bold: true,
              },
              {
                text: addCommas(uniqueItems),
                fontSize: 11,
                margin: [30, 0, 0, 0],
              },
            ],
            [
              {
                text: "UNITS",
                fontSize: 11,
                color: "#707070",
                bold: true,
              },
              {
                text: addCommas(totalUnits),
                fontSize: 11,
                margin: [30, 0, 0, 0],
              },
            ],
            [
              {
                text: "TOTAL PRICE",
                color: "#707070",
                bold: true,
              },
              {
                text: "$" + addCommas(totalCost),
                fontSize: 11,
                margin: [30, 0, 0, 0],
              },
            ],
            [
              {
                text: "GROSS",
                color: "#707070",
                bold: true,
              },
              {
                text: "$" + addCommas(totalGross),
                fontSize: 11,
                margin: [30, 0, 0, 0],
              },
            ],
            [
              {
                text: "TAX",
                color: "#707070",
                bold: true,
              },
              {
                text: "$" + addCommas(totalTax),
                fontSize: 11,
                margin: [30, 0, 0, 0],
              },
            ],
          ],
        },
      };

      const content = [
        header,
        vendorLine,
        {
          text: "PO Summary",
          bold: true,
          margin: [0, 40, 0, 0],
        },
        poSummary,
        " ",
        " ",
        {
          layout: {
            hLineColor: function (i, node) {
              return "white";
            },
            vLineColor: function (i, node) {
              return "white";
            },
            vLineWidth: function (i, node) {
              return i === 0 ? 1 : 0;
            },
          },
          table: {
            headerRows: 1,
            widths: ["auto", "auto", "*", "*", "*"],
            body: [
              [
                {
                  text: "BRAND",
                  style: "header",
                },
                {
                  text: "PRODUCT NAME",
                  style: "header",
                },
                {
                  text: "QTY",
                  style: "rightHeader",
                },
                {
                  text: "COST PER",
                  style: "rightHeader",
                },
                {
                  text: "ITEM TOTAL",
                  style: "rightHeader",
                },
              ],
              ...lineItemsFormatted,
            ],
          },
        },
      ];

      if (exportedPdfFileName.length === 0) {
        // TBD: show snackbar
        return;
      }
      pdfMake
        .createPdf({
          pageSize: "A4",
          pageMargins: [20, 40, 20, 40],
          content,
          styles: tableStyles,
        })
        .download(exportedPdfFileName);
    } catch (e) {
      enqueueSnackbar(e.message, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom'
        }
      });
    } finally {
      setIsExportModalOpen(false);
      setExportedPdfFileName("");
      setIsExportPdfModalOpen(false);
    }
  };

  const generatePdfFileName = () => {
    const po = purchaseOrder && purchaseOrder.po_number;
    const company = org.name;
    const vendor =
      purchaseOrder &&
      purchaseOrder.distributor &&
      purchaseOrder.distributor.name;
    const location =
      purchaseOrder &&
      purchaseOrder.distributor &&
      purchaseOrder.distributor.address;
    return `PO #${po} ${vendor} ${location} ${company}.pdf`;
  };

  const deletePO = async () => {
    try {
      setIsDeletingPO(true);
      await OrganisationService.po.delete(orgId, purchaseOrder.po_number);
      // TBD: show snackbar
      navigate(`/account/orders`);
    } catch (e) {
      setIsDeletePOModalOpen(false);
      // TBD: show snackbar
    } finally {
      setIsDeletingPO(false);
    }
  };

  const duplicatePO = async () => {
    setIsDuplicatingPO(true);
    try {
      const { data: poData } = await OrganisationService.po.listByPoNumber(
        orgId,
        po
      );

      poData.location_id = loc.id;
      poData.location = loc.location_name;
      poData.location_name = loc.location_name;
      poData.vendor_id = vendor.id;
      poData.brand_id = vendor.id;
      poData.brand = vendor.distributor_name;
      poData.vendor = vendor.distributor_name;

      const lineItems = poData.lineItems.map((item) => {
        return {
          ...item,
          order_qty: item.case_count * item.case_qty,
          cost: item.order_qty * item.auc,
        };
      });

      setPurchaseOrder({
        ...poData,
        mode: "CREATE",
        lineItems: [...lineItems, recordToInsert],
      });


      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('po', -1);

      navigate({
        search: searchParams.toString()
      });

      setIsConfirmDuplicateModalOpen(false);
      enqueueSnackbar("Successfully duplicated purchase order, you are now viewing the copy", { variant: "success" });
    } catch (e) {
      console.log(e.message);
    } finally {
      setIsDuplicatingPO(false);
    }
  };

  // get class hierarchy menu
  const fetchClassMenu = async () => {
    try {
      const { data } = await OrganisationService.forecast(orgId).productTypes();
      const products = data.hierarchy.children[0];
      setProductTypes(products);

      // flatten category hierarchy to one big array
      const flattened = [
        ...products.children.map((d) => ({
          id: d.id,
          name: d.name,
        })),
        ...products.children.reduce((acc, curr) => {
          return [
            ...acc,
            ...curr.children.map((d) => ({
              id: d.id,
              name: d.name,
            })),
          ];
        }, []),
      ];

      // convert array to object
      const map = {};
      flattened.map((a) => {
        map[a.id] = a.name;
      });
      setProductTypeMap(map);
    } catch (err) {
      console.log(err);
      // TBD: show snackbar
    }
  };

  // load menu items on mount
  useEffect(() => {
    fetchClassMenu().then();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 50);
  }, [productTypeFilter, brandFilter]);

  // brands in line items
  const poBrands = [
    ...new Set((purchaseOrder.lineItems || []).map((i) => i.brand_name)),
  ];

  // filtered line items

  const filteredLineItems = () => {
    return (purchaseOrder.lineItems || [])
      .filter((item) => !brandFilter || item.brand_name === brandFilter)
      .filter(
        (item) => !productTypeFilter || item.class_id === +productTypeFilter.id
      );
  }

  return (
    <div id="capture" style={{ padding: "25px" }}>
      <div className="clearfix">
        <div className="float-left">
          <div>
            <h2 style={{ textTransform: "uppercase" }}>{title}</h2>
          </div>
        </div>
        <NavMenu buttons={buttons} />
      </div>
      {isLoadingPo && <Loader />}
      {!isLoadingPo && (
        <Stack rowGap={5} sx={{ marginTop: 4 }}>
          <Box display="flex" columnGap={30}>
            <Box>
              <Box>Vendor</Box>
              <Box>{purchaseOrder?.brand || purchaseOrder?.vendor}</Box>
            </Box>
            <Box>
              <Box>Ship To</Box>
              <Box>
                {purchaseOrder?.location_name || purchaseOrder?.location}
              </Box>
            </Box>
            <Box>
              <Select
                labelId="status-label"
                id="status-id"
                sx={{ width: "200px" }}
                size="small"
                label="status"
                placerholder="Status"
                value={purchaseOrder.status}
                onChange={(e) => {
                  setPurchaseOrder({
                    ...purchaseOrder,
                    status: e.target.value,
                  });
                }}
              >
                <MenuItem value={"NEEDS_APPROVAL"}>Needs Approval</MenuItem>
                <MenuItem value={"NOT_SUBMITTED"}>Not Submitted</MenuItem>
                <MenuItem value={"REQUESTED"}>Requested</MenuItem>
                <MenuItem value={"CONFIRMED"}>Confirmed</MenuItem>
                <MenuItem value={"DELIVERED"}>Delivered</MenuItem>
                <MenuItem value={"CANCELLED"}>Cancelled</MenuItem>
                <MenuItem value={"PAID"}>Paid</MenuItem>
                <MenuItem value={"REJECTED"}>Rejected</MenuItem>
                <MenuItem value={"DELAYED"}>Delayed</MenuItem>
                <MenuItem value={"ISSUE"}>Issue</MenuItem>
                <MenuItem value={"DELETED"}>Deleted</MenuItem>
              </Select>
            </Box>
          </Box>
          <Box display="flex" columnGap={30}>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <MobileDatePicker
                  label="Expected Delivery Date"
                  value={purchaseOrder.delivery_date}
                  onChange={(val) => {
                    setPurchaseOrder({
                      ...purchaseOrder,
                      delivery_date: val,
                    });
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
            <Box>
              <TextField
                type="textarea"
                name="terms"
                label="Terms"
                multiline
                rows={3}
                maxRows={3}
                sx={{ width: "400px" }}
                style={{ resize: "none" }}
                value={purchaseOrder.terms}
                onChange={(e) => {
                  setPurchaseOrder({
                    ...purchaseOrder,
                    terms: e.target.value,
                  });
                }}
              />
            </Box>
            <Box>
              <Figure>
                <FigureLabel>Total Cost</FigureLabel>
                <FigureValue
                  style={{
                    fontSize: "20px",
                    color: "#00A9A7",
                    fontWeight: "bold",
                  }}
                >
                  ${addCommas(totalCost)}
                </FigureValue>
              </Figure>
              <Figure>
                <FigureLabel>Gross</FigureLabel>
                <FigureValue
                  style={{
                    fontSize: "20px",
                    color: "#00A9A7",
                    fontWeight: "bold",
                  }}
                >
                  ${addCommas(totalGross)}
                </FigureValue>
              </Figure>
              <Figure
                style={{
                  marginBottom: "30px",
                }}
              >
                <FigureLabel>Tax</FigureLabel>
                <FigureValue
                  style={{
                    fontSize: "20px",
                    color: "#00A9A7",
                    fontWeight: "bold",
                  }}
                >
                  ${addCommas(totalTax)}
                </FigureValue>
              </Figure>
              <Figure>
                <FigureLabel>Total Units:</FigureLabel>
                <FigureValue>{addCommas(totalUnits)}</FigureValue>
              </Figure>
              <Figure>
                <FigureLabel>Unique Items:</FigureLabel>
                <FigureValue>
                  {getUniqueItems()}
                </FigureValue>
              </Figure>
            </Box>
          </Box>
          <Box>
            {!isLoadingPo && (
              <FiltersContainer>
                <Filter
                  value={brandFilter}
                  defaultLabel="All brands"
                  mainLabel="Brand: "
                  options={filteredBrands.map((b) => ({
                    value: b,
                    label: b,
                  }))}
                  onChange={(newVal) => {
                    setBrandFilter(newVal);
                  }}
                />
              </FiltersContainer>
            )}
            {!isLoadingPo && (
              <FiltersContainer>
                <ProductTypeFilter
                  filter={filteredProductTypes}
                  categories={productTypes && productTypes.children}
                  value={productTypeFilter && productTypeFilter.id}
                  map={productTypeMap}
                  onChange={(p) => {
                    setProductTypeFilter(p);
                  }}
                />
              </FiltersContainer>
            )}
          </Box>
        </Stack>
      )}

      {!isLoadingPo && (
        <Card className="card-listing" style={{ marginTop: "20px" }}>
          <CardContent>
            {purchaseOrder.lineItems && (
              <DataTable
                data={filteredLineItems()}
                columns={columns()}
                noHeader
                highlightOnHover
                striped
                customStyles={{
                  rows: {
                    style: {
                      minHeight: "35px",
                    },
                  },
                }}
              />
            )}
          </CardContent>
        </Card>
      )}
      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        open={isPOHistoryModalOpen}
        onClose={() => setIsPOHistoryModalOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 200,
        }}
      >
        <div>
          <PurchaseOrderHistory
            onClose={() => {
              setIsPOHistoryModalOpen(false);
            }}
            orgId={orgId}
            poNumber={purchaseOrder && purchaseOrder.po_number}
          />
        </div>
      </Dialog>
      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        open={isConfirmDuplicateModalOpen}
        onClose={() => setIsConfirmDuplicateModalOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 200
        }}>
          <DialogTitle>Duplicate Purchase Order</DialogTitle>
          <div className={classes.paper} style={{ padding: "0 24px 24px 24px", borderRadius: '5px' }}>
            { !isDuplicatingPO && <div  style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
              <div>Please select a vendor and location</div>
              <div>
                <Autocomplete
                  size="small"
                  disablePortal
                  id="distributor_name"
                  value={vendor}
                  onChange={(_, newValue) => {
                    setVendor(newValue);
                  }}
                  options={(vendors ?? []).map((v) => ({
                    ...v,
                    value: v.id,
                    label: v.distributor_name,
                  }))}
                  renderInput={(params) => (
                    <TextField
                      sx={{ width: "400px" }}
                      {...params}
                      label="Which Vendor is this for?"
                    />
                  )}
                />
              </div>
              <div>
                <Autocomplete
                  size="small"
                  disablePortal
                  id="location"
                  value={loc}
                  onChange={(_, newValue) => {
                    setLocation(newValue);
                  }}
                  options={locations
                    .filter((l) => l.id !== -1)
                    .map((l) => ({
                      ...l,
                      value: l.id,
                      label: l.location_name,
                    }))}
                  renderInput={(params) => (
                    <TextField
                      sx={{ width: "400px" }}
                      {...params}
                      label="Where is it going?"
                    />
                  )}
                />
              </div>
              <div style={{ display: 'flex' }}>
                <OKButton style={{ marginRight: "15px" }} onClick={duplicatePO}>
                  Duplicate
                </OKButton>
                <CancelButton onClick={() => setIsConfirmDuplicateModalOpen(false)}>
                  Cancel
                </CancelButton>
              </div>
            </div> }
            { isDuplicatingPO && <div style={{ display: 'flex', gap: '20px', alignItems: 'center', padding: '10px 20px' }}>
              <CircularProgress style={{ fontSize: '14px' }} />
              Duplicating PO...
            </div> }
          </div>
      </Dialog>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isExportModalOpen}
        onClose={() => setIsExportModalOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 200,
        }}
      >
        <Fade in={isExportModalOpen}>
          <div className={classes.paper}>
            <ExportModalHeader>
              Choose how you want to export the purchase order
            </ExportModalHeader>
            <ExportOption onClick={EmailExport}>
              <EmailIcon />
              <span>Email it to me</span>
            </ExportOption>
            <ExportOption onClick={ExportAsPDF}>
              <PictureAsPdfIcon />
              <span>Export as PDF</span>
            </ExportOption>
            <ExportOption onClick={ExportAsExcel}>
              <PictureAsExcel />
              <span>Export as Excel</span>
            </ExportOption>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isExportPdfModalOpen}
        onClose={() => setIsExportPdfModalOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isExportPdfModalOpen}>
          <div className={classes.paper}>
            <ExportModalHeader>Choose file name</ExportModalHeader>
            <div style={{ marginBottom: "30px", width: "400px" }}>
              <TextField
                id="export-pdf-basic"
                value={exportedPdfFileName}
                style={{ width: "100%" }}
                label="PDF File Name"
                onChange={(e) => {
                  setExportedPdfFileName(e.target.value);
                }}
                variant="outlined"
              />
            </div>
            <OKButton onClick={saveAsPdf}>Export</OKButton>
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isDeletePOModalOpen}
        onClose={() => setIsDeletePOModalOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 200,
        }}
      >
        <Fade in={isDeletePOModalOpen}>
          <div className={classes.paper} style={{ padding: "10px" }}>
            <div>Are you sure you want to delete this purchase order?</div>
            <div style={{ marginTop: "30px" }}>
              <DeleteButton style={{ marginRight: "25px" }} onClick={deletePO}>
                {isDeletingPO ? "Deleting..." : "Delete"}
              </DeleteButton>
              <CancelButton onClick={() => setIsDeletePOModalOpen(false)}>
                Cancel
              </CancelButton>
            </div>
          </div>
        </Fade>
      </Modal>
      <Backdrop
        open={isSaving}
        sx={{
          background: "rgba(255, 255, 255, 0.8)",
          color: "black",
          position: "fixed",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
        }}
      >
        <Box className={classes.loader}>
          <CircularProgress />
          <div style={{ background: "white" }}>Saving Purchase Order...</div>
        </Box>
      </Backdrop>
      <Modal
        open={isClassModalOpen}
        onClose={() => setIsClassModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ClassSelector
            onSelect={onSelect}
            serviceProvider={forecastAPI}
            key={1}
          />
        </Box>
      </Modal>
    </div>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default PurchaseOrderEdit;
